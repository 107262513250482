<template>
  <div class="box">
    <div class="lunbo">
      <el-carousel :interval="3000" arrow="always" height="410px" indicator-position="none">
        <el-carousel-item v-for="(item,index) in bannerList" :key="index">
          <div class="title">{{item.journalisTitle}}</div>
          <el-image class="img" :src="echoImg(item.bannerUrl)" fit="contain" @click="goDetails('',item.id)"></el-image>
        </el-carousel-item>
      </el-carousel>
    </div>
    <ul class="newsBox" v-for="(item,index) in subjectList" :key="index" :class="'newsBox_'+index">
      <li>
        <div class="line" :style="{'background':color}"></div>
        <div class="title" :style="{borderColor:color}" @click="more(item.navigationName,item.id,item.navigationType)">{{item.navigationName}}</div>
        <div class="more" @click="more(item.navigationName,item.id,item.navigationType)">更多</div>
        <ul>
          <template v-for="(dates,idxs) in item.journalism" @key="idxs">
            <li v-if="idxs<9" @click="goDetails(item.navigationName,dates.id)" :key="idxs">
              <div class="name">{{dates.journalisTitle}}</div>
              <div class="time">{{toDate(dates.createTime,"yyyy-MM-dd")}}</div>
            </li>
          </template>
        </ul>
      </li>
    </ul>
    <ul class="pictures">
      <div class="title" @click="more('精彩图片','img','0')">精彩图片</div>
      <template v-for="(item,index) in mapDepotList" @key="index">
        <li v-if="index<9" :key="index" v-on:mousemove="showMousemove(index)" v-on:mouseleave="showMouseleave(index)" @click="goDetails('',item.id)">
          <el-image class="img" :src="echoImg(item.bannerUrl)" @click="goDetails('', item.id)" fit="cover"></el-image>
          <div class="name">{{item.journalisTitle}}</div>
<!--          <div class="info" v-if="item.isShow">{{item.journalisTitle}}</div>-->
        </li>
      </template>
    </ul>
    <ul class="partners">
      <div class="title ">友情链接</div>
      <div class="contains">
        <ul :style="{ left: calleft + 'px', width: widthData + 'px' }" v-on:mouseover="stopMove()" v-on:mouseout="move()" class="imgBoxoul">
          <li v-for="(item, index) in linksList2" :key="index" ref="lis">
            <div class="lbTitle" @click="openLinks(item.linkUrl)">{{item.linkName}}</div>
<!--            <el-image class="img" :src="echoImg(item.imgUrl)" @click="openLinks(item.linkUrl)" fit="fit"></el-image>-->
          </li>
          <li v-for="(dates, idx) in linksList2" :key="'c_' + idx" ref="lis1">
            <div class="lbTitle" @click="openLinks(dates.linkUrl)" >{{dates.linkName}}</div>
<!--            <el-image class="img" :src="echoImg(dates.imgUrl)" @click="openLinks(dates.linkUrl)" fit="fit"></el-image>-->
          </li>
        </ul>
      </div>
    </ul>
  </div>
</template>
<script>
import { selectLink,selectMapDepot,selectBanner,selectList,getTextarea } from "@/api/template";
import { mapState } from "vuex";
export default {
  name: "Index",
  data() {
    return {
      linksList: [],
      mapDepotList: [],
      linksList1:[],
      linksList2:[],
      bannerList: [],
      subjectList: [],
      synopsis:'',
      introduce:'',
      calleft: 0,
      speed: 1,
      timer: '',

      calleft1: 0,
      speed1: 1,
      timer1: '',
      lyList:['../images/gg1.jpg','../images/gg2.jpg'],
      subjectList1:{},
      eventSeriesList:[],
      noticeList:[],
      memberList:[],
      comprehensiveList:[],
      hotEventsList:[],
      newsList:[],
      businessList:[],
      picturesList:[]
    };
  },
  created(){
    this.init()
    this.move()
  },
  computed: {
    widthData(){
      return 190 * Number(this.linksList2.length * 2)
    },
    ...mapState(["color"]),
  },
  mounted() {
    // let imgBox = document.getElementsByClassName("imgBoxoul")[0];
    // imgBox.innerHTML += imgBox.innerHTML;
  },
  methods: {
    init(){
      this.getSelectBanner()//轮播图
      this.getSelectList()//首页主体查询
      this.getSelectMapDepot()//精彩图片
      this.getSelectLink()//友情链接查询
    },

    getSelectLink(){
      let self = this
      selectLink().then(res =>{
        if(res.code == 200){
          res.data.forEach(item =>{
            if(!item.imgUrl){
              this.linksList1.push(item)
            }else if(item.imgUrl){
              this.linksList2.push(item)
            }
          })
          this.linksList = res.data
        }else {
          self.$message(res.msg);
        }
      }).catch(() =>{})
    },
    getSelectBanner(){
      let self = this
      selectBanner().then(res =>{
        if(res.code == 200){
          this.bannerList = res.data
        }else {
          self.$message(res.msg);
        }
      }).catch(() =>{})
    },
    getSelectMapDepot(){
      let self = this
      selectMapDepot().then(res =>{
        if(res.code == 200){
          res.data.forEach(item=>{
            item.isShow = false
          })
          this.mapDepotList = res.data
        }else {
          self.$message(res.msg);
        }
      }).catch(() =>{})
    },
    showMousemove(index){
      this.mapDepotList[index].isShow = true
    },
    showMouseleave(index){
      this.mapDepotList[index].isShow = false
    },
    getSelectList(){
      let self = this
      selectList().then(res =>{
        if(res.code == 200){
          this.subjectList = res.data
        }else {
          self.$message(res.msg);
        }
      }).catch(() =>{})
    },
    openLinks(linkUrl){
      window.open(linkUrl,'_blank')
    },
    more(navigationName,id,type){
      this.$router.push({params: {name:navigationName,id:id,type: type},name:'eighth-list'})
    },
    goDetails(navigationName,id) {
      let data = {
        name: navigationName,
        id: id,
      }
      window.sessionStorage.setItem('detailsValue', JSON.stringify(data))
      this.$router.push('/eighth-details')
    },
    getTextareaFun(journalisContent,type){
      let params = {
        "journalisContent":journalisContent
      }
      getTextarea(params).then(res =>{
        if(type == 'synopsis'){
          this.synopsis = res.data.dataHtml
        }
        if(type == 'introduce'){
          this.introduce = res.data.dataHtml
        }
      })
    },
    move() {
      this.timer = setInterval(this.starMove, 20);
    },
    //开始移动
    starMove() {
      this.calleft -= 1.2;//*this.speed
      if (this.calleft <= -this.linksList2.length*191) {
        this.calleft = 0;
      }
    },
    //鼠标悬停时停止移动
    stopMove() {
      clearInterval(this.timer);
    },
  },
};
</script>
<style lang="scss" scoped>
.box{
  .lunbo{
    position: relative;
    width: 658px;
    height: 410px;
    display: inline-block;
    margin: 30px 15px 0 ;
    .title{
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      background: rgba(0,0,0,0.6);
      z-index: 99;
      color: #fff;
      height: 40px;
      line-height: 40px;
      padding: 0 20px;
      font-weight: bold;
    }
    .img{
      width: 100%;
      cursor: pointer;
    }
    /deep/ .el-carousel__arrow{
      background-color:rgba(31,45,61,.4);
    }
    /deep/.el-carousel__indicators{
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
  .newsBox{
    width: 545px;
    height: 360px;
    position: relative;
    display: inline-block;
    vertical-align: top;
    background: rgba(255,255,255,.2);
    margin: 30px 15px 0;
    padding: 20px;
    font-size: 16px;
    line-height: 40px;
    overflow: hidden;
    .line{
      position: absolute;
      width: 100%;
      height: 5px;
      left: 0;
      top: 0;
    }
    .title{
      color: #000;
      font-size: 24px;
      font-weight: bold;
      cursor: pointer;
      margin-bottom: 20px;
      border-bottom: 1px solid;
    }
    .more{
      position: absolute;
      right: 20px;
      top: 10px;
      cursor: pointer;
    }
    .name{
      float: left;
      width: 80%;
      cursor: pointer;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .time{
      float: right;
      width: 20%;
      text-align: right;
      cursor: pointer;
    }
    .title:hover{
      color: green;
    }
    .more:hover{
      color: green;
    }
    ul li:hover{
      color: green;
    }
  }
  .newsBox_0{
    width: 472px;
    height: 370px;
  }
  .pictures{
    margin-top: 40px;
    .title{
      color: #000;
      font-size: 24px;
      font-weight: bold;
      text-align: center;
      cursor: pointer;
    }
    .title:hover{
      color: green;
    }
    li{
      position: relative;
      float: left;
      width: 275px;
      height: 200px;
      margin-top: 20px;
      padding: 0 15px;
      cursor: pointer;
      .img{
        width: 100%;
        height: 100%;
      }
      .name{
        position: absolute;
        //width: 100%;
        height: 40px;
        line-height: 40px;
        left: 15px;
        right: 15px;
        bottom: 0;
        color: #fff;
        padding: 0 15px;
        background: rgba(0,0,0,.6);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .info{
        position: absolute;
        left: 15px;
        top: 0;
        right: 15px;
        bottom: 0;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        text-align: center;
        padding: 20px 10px;
        font-size: 14px;
        color: #fff;
        font-weight: bold;
        background: rgba(0,0,0,.7);
      }
      .info:after{
        position: absolute;
        left: 50%;
        bottom: 0;
        margin-left: -5px;
        content: '';
        width: 0;
        height: 0;
        border: 5px solid transparent;
        border-bottom: 7px solid #fff;
      }
    }
  }
  .pictures:after{
    content: "";
    display: block;
    clear: both;
  }
  .partners{
    padding: 0 20px;
    margin-top: 40px;
    clear: both;
    .title{
      width: 100%;
      color: #000;
      font-size: 24px;
      font-weight: bold;
      text-align: center;
      cursor: pointer;
    }
    .title:hover{
      color: green;
    }
    .contains {
      position: relative;
      //padding: 20px 0;
      overflow-y: auto;
      width: 100%;
      height: 50px;
      //height: 140px;
      overflow: hidden;
      margin: 0 auto 20px;
      ul {
        width: 100%;
        //height: 120px;
        position: absolute;
        left: 0px;
        cursor: pointer;
        z-index: 10;
        overflow: hidden;
        margin: 20px auto;
        li {
          float: left;
          width: 150px;
          //height: 120px;
          //padding: 30px 48px 0;
          overflow: hidden;
          text-align: center;
          border-right: 1px solid #eee;
          padding: 0 20px;
          .img{
            height: 100%;
            cursor: pointer;
          }
          .lbTitle{
            font-size: 20px;
            color: #333;
            font-weight: bold;
          }
        }
      }
    }
  }
}

</style>
